<template>
  <cb-container>
    <section class="contact-wrapper">
      <h2 class="title">
        CGBank to Inspire You!
      </h2>
      <cb-btn
        url="https://docs.google.com/forms/d/e/1FAIpQLSfwFX6ww3Tq6sEA4n9tQV6TKYwnZaxz4t4KFj2coUN9TFQbpQ/viewform"
        class="contact-btn"
        text="お問い合わせ"
      />
    </section>
  </cb-container>
</template>

<style lang="scss" scoped>
  .contact-wrapper {
  height: 100%;
  margin: 0 auto;
  width: fit-content;
  gap: clamp(2.5rem, 1.7777rem + 3.0817vw, 3.75rem);
  padding: clamp(2.5rem, 1.0555rem + 6.1633vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
  }

  > .title {
    font-family: $english;
    font-size: clamp(1.75rem, 1.0277rem + 3.0817vw, 3rem);
  }
}

.contact-btn {
  width: fit-content;
  padding: 1rem 2.5rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
